import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'easter',
  formId: 'Easter%20offerings%20Denmark',
  title: 'Påske',
  subTitle: 'Få mere ud af påsken!',
  theme: 'spring',
  icon: '/icons/easter.svg',
  image: '/assets/images/services/easter/easter_illustration.svg',
  sections: [
    {
      sectionId: 'hero',
      label: 'Påske',
      title: 'Få mere ud af påsken!',
      description:
        'Hvis I har lyst til at fejre de smukke skift i årstider og de helligdage vi har i Danmark får I muligheden lige her. Fra flotte gaver til virtuelle events!.',
      image: '/assets/images/services/easter/easter_illustration.svg',
      icon: '/icons/easter.svg',
      buttonText: 'Få et tilbud',
    },
    {
      sectionId: 'options',
      title: 'Få alt til den perfekte påske!',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/easter/service_1.svg',
          title: 'Påskegaver',
          description:
            'Om det er den perfekte påskekurv, påskeæg eller noget til at slukke påsketørsten med så finder I det lige her.',
        },
        {
          icon: '/assets/images/services/easter/service_2.svg',
          title: 'Virtuelle events',
          description:
            'Hvis I stadigvæk holder lidt afstand kan I altid mødes over et virtuelt cocktail eller kæmpe om at blive virksomhedens online bingo mesteren ',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Hvordan virker det?',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/easter/journey_1.svg',
          title: 'Fortæl os hvad I gerne vil have',
          description: 'Vi har både noget til enhver smag så fortæl os hvad I ønsker.',
        },
        {
          icon: '/assets/images/services/easter/journey_2.svg',
          title: 'Vi sender dig et tilbud',
          description: 'Vi gør os umage med et hurtigt tilbud og så det passer perfekt til jer.',
        },
        {
          icon: '/assets/images/services/easter/journey_3.svg',
          title: 'Accepter!',
          description: 'Sådan. Nu er I klar til påske.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Hvorfor elsker vore kunder at fejre højtider gennem os?',
      description: '',
      image: '/assets/images/services/easter/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-bulb-outline',
          title: 'Inspiration',
          description: 'Spar tid på at gennemgå hele Internettet og find det bedste her.',
        },
        {
          icon: 'eva eva-clock-outline',
          title: 'Få det lavet så det passer jeres profil',
          description: 'Ikke 2 ting behøver at være ens. Vi sikre at det passer til jeres smag og pengepung.',
        },
        {
          icon: 'eva eva-gift-outline',
          title: 'Nem distribution',
          description: 'Om I vil have gaver på kontoret eller sendt hjem så fikser vi det!',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Vidste du at Good Monday kan klar alle højtider og specielle lejligheder?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Få et tilbud',
    },
  ],
}
